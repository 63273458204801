const modes = {
  "all-options": {
    title: "ALL OPTIONS", // Título en la tab de quotebook
    id: "all-options",
    multiservice: true, // Solo all-options tiene multiservice en true
    modeId: process.env.VUE_APP_ALLOPTIONS_ID, // id del modo para el backend
    departmentId: -1, // Nos sirve para solicitar los accessorials correspondientes al modo
    notificationsNumber: 0, // Cantidad de notificaciones que llegan por WS. Poner cuidado a all-options
    trucks: true, // ¿Se muestran trucks en este modo?
    carriers: true, // ¿Se muestran carriers en este modo?
    partial: true, // ¿Se muestran parciales en este modo?
    flatbed: true,
    reefer: true,
    activeTab: false, // Con esto se controla la visibilidad de la tab en quotebook
    enabled: false, // Con esto activamos o desactivamos la tab (a nivel visual es un "disabled")
    quoteBook: true, // Con esto renderizamos (o no) la tab
  },
  ltl: {
    title: "LTL-PARCEL",
    id: "ltl",
    multiservice: false,
    modeId: process.env.VUE_APP_MODE_LTL_ID,
    departmentId: 1,
    notificationsNumber: 8,
    trucks: false,
    carriers: true,
    partial: true,
    flatbed: false,
    reefer: false,
    activeTab: false,
    enabled: true,
    quoteBook: true,
  },
  ftl: {
    title: "FTL DRY VAN",
    id: "ftl",
    multiservice: false,
    modeId: process.env.VUE_APP_MODE_FTL_ID,
    departmentId: 2,
    notificationsNumber: 3,
    trucks: true,
    carriers: false,
    flatbed: false,
    reefer: false,
    activeTab: false,
    enabled: true,
    quoteBook: true,
  },
  expedited: {
    title: "EXPEDITED",
    id: "expedited",
    multiservice: false,
    modeId: process.env.VUE_APP_EXPEDITED_ID,
    departmentId: 3,
    notificationsNumber: 3,
    trucks: true,
    carriers: false,
    flatbed: false,
    reefer: false,
    activeTab: false,
    enabled: true,
    quoteBook: true,
  },
  // Insurance no es un modo de cotización en el quotebook
  insurance: {
    title: "INSURANCE",
    id: "insurance",
    multiservice: false,
    modeId: "INSURANCE",
    departmentId: 6,
    notificationsNumber: 0,
    trucks: true,
    carriers: false,
    flatbed: false,
    reefer: false,
    activeTab: false,
    enabled: true,
    quoteBook: false,
  },
  flatbed: {
    title: "FLATBED",
    id: "flatbed",
    multiservice: false,
    modeId: process.env.VUE_APP_HH_MODE_ID,
    departmentId: 5,
    notificationsNumber: 2,
    trucks: false,
    carriers: false,
    flatbed: true,
    partial: true,
    reefer: false,
    activeTab: false,
    enabled: true,
    quoteBook: true,
  },
  reefer: {
    title: "REEFER FTL",
    id: "reefer",
    multiservice: false,
    modeId: process.env.VUE_APP_TC_MODE_ID,
    departmentId: 4,
    notificationsNumber: 3,
    trucks: false,
    carriers: false,
    flatbed: false,
    reefer: true,
    activeTab: false,
    enabled: true,
    quoteBook: true,
  },
  drayage: {
    title: "DRAYAGE",
    id: "drayage",
    multiservice: false,
    modeId: process.env.VUE_APP_MODE_DRAYAGE,
    departmentId: 6,
    notificationsNumber: 1,
    trucks: false,
    carriers: false,
    flatbed: false,
    reefer: false,
    activeTab: false,
    enabled: true,
    quoteBook: true,
  },
};

function setAllOptionsNotificationsNumber() {
  /* eslint-disable */
  let notificationNumberAllOptions = 0;

  if (modes.ltl.enabled) {
    notificationNumberAllOptions += 5;
  }
  if (modes.ftl.enabled) {
    notificationNumberAllOptions += 2;
  }
  if (modes.expedited.enabled) {
    notificationNumberAllOptions += 1;
  }
  if (modes.reefer.enabled) {
    notificationNumberAllOptions += 1;
  }
  if (modes.flatbed.enabled) {
    notificationNumberAllOptions += 1;
  }
  if (modes.drayage.enabled) {
    notificationNumberAllOptions += 1;
  }
  modes["all-options"].notificationsNumber = notificationNumberAllOptions;
}

function allOptionsEnablementHandling() {
  const enabledModes = Object.values(modes).filter(
    (mode) => mode.id !== "all-options" && mode.enabled
  );
  if (enabledModes.length > 1) {
    modes["all-options"].enabled = true;
    setAllOptionsNotificationsNumber();
    return;
  }
  modes["all-options"].enabled = false;
}

export function modifyModesEnablement(newModes) {
  Object.values(newModes).forEach((mode) => {
    modes[mode.id].enabled = mode.enabled;
  });
  allOptionsEnablementHandling();
}

export function initEnablementModes() {
  const settings = JSON.parse(localStorage.getItem("user-settings"));
  const canDispatchModes = settings?.can_dispatch_modes;
  if (!canDispatchModes) {
    return;
  }
  Object.keys(canDispatchModes).forEach((mode) => {
    modes[mode].enabled = canDispatchModes[mode];
  });
  allOptionsEnablementHandling();
}

export default modes;
