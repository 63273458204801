import {
  modes,
  getTokenUserInfo,
  identifyLoadStatus,
  removeLoadingTemplateFromDOM,
  identifyDrayageLoad,
} from "../routerHelpers";

function validateQuoteBookMode(to, next) {
  if (modes.includes(to.params.quoteType)) {
    next();
  } else {
    next("/404");
  }
}

function convertToBoolean(value) {
  // eslint-disable-next-line no-nested-ternary
  return value === 'true' ? true : value === 'false' ? false : value;
}

const quoteBookRoutes = [
  {
    path: "quote-book/carrier-selection/:loadId?",
    name: "CarriersInfo",
    meta: {
      title: "Book a Load | Rates | Portal",
    },
    component: () => import("../../views/QuoteBook/CarrierSelection/Index.vue"),
    beforeEnter: async (to, from, next) => {
      if (to.params.loadId && from.name !== "MyLoads") {
        const loadIsQuoted = await identifyLoadStatus(to.params.loadId);
        if (loadIsQuoted) {
          next();
        } else {
          next(`/my-loads/${to.params.loadId}`);
        }
      } else if (to.params.loadId && from.name === "MyLoads") {
        next();
      }
      removeLoadingTemplateFromDOM();
    },
  },
  {
    path: `quote-book/load-tendering/:loadId/:carrierId/`,
    name: "StopsInfo",
    meta: {
      title: "Book a Load | Tender | Portal",
    },
    component: () => import("../../views/QuoteBook/LoadTendering/StopsResume.vue"),
    beforeEnter: async (to, from, next) => {
      // Validamos que estos valores existan en la ruta ya que son requeridos
      if (to.query.pickup === undefined || to.query.pickup === null || to.query.insurance === undefined || to.query.insurance === null) {
        next(`/quote-book/carrier-selection/${to.params.loadId}?`);
        return;
      }
      // Validamos el tipo de dato de estos valores y convertimos a booleanos
      if (typeof to.query.pickup === "string" || typeof to.query.insurance === "string" || typeof to.query.loyaltyInsurance === "string") {
        to.query.pickup = convertToBoolean(to.query.pickup);
        to.query.insurance = convertToBoolean(to.query.insurance);
        to.query.loyaltyInsurance = convertToBoolean(to.query.loyaltyInsurance);
      }
      if (getTokenUserInfo().can_dispatch === false) {
        next("/");
        return;
      }
      if (to.params.loadId) {
        const loadIsQuoted = await identifyLoadStatus(to.params.loadId);
        if (loadIsQuoted) {
          next();
        } else {
          next(`/my-loads/${to.params.loadId}`);
        }
      }
      removeLoadingTemplateFromDOM();
    },
  },
  {
    path: "quote-book/:quoteType/:loadId?",
    name: "QuoteBook",
    meta: {
      title: "Book a Load | Load Creation | Portal",
    },
    component: () => import("../../views/QuoteBook/Index.vue"),
    beforeEnter: async (to, from, next) => {
      if (to.params.loadId) {
        const isDrayage = await identifyDrayageLoad(to.params.loadId);
        if (isDrayage && to.params.quoteType !== 'drayage') {
          to.params.quoteType = 'drayage';
        }
        const loadIsQuoted = await identifyLoadStatus(to.params.loadId);
        if (loadIsQuoted) {
          validateQuoteBookMode(to, next);
        } else {
          next(`/my-loads/${to.params.loadId}`);
        }
      }
      validateQuoteBookMode(to, next);
      removeLoadingTemplateFromDOM();
    },
  },
];

export default quoteBookRoutes;
