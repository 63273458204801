import { errorMessage } from "../../utils/ErrorMessages";
import Repository from "../../services/repositories/RepositoryFactory";

const account = Repository.service("account");

export default {
  namespaced: true,
  state: {
    allAccounts: [],
    accounts: {},
    selectedUserID: null,
    accountId: null,
    allInfoAccounts: [],
    accountNameUserView: "",
    userSettings: null,
    trackingPreferences: {}
  },
  getters: {
    getAccounts(state) {
      return state.allAccounts;
    },
    getOneAccount: (state) => (id) => {
      if (id in state.accounts) {
        return state.accounts[id];
      }
      return null;
    },
    getAccountNameUserView(state) {
      return state.accountNameUserView;
    },
    getTrackingPreferencesData(state) {
      return state.trackingPreferences;
    },
  },
  mutations: {
    setTrackingPreferences(state, payload) {
      state.trackingPreferences = payload;
    },
    setAccounts(state, payload) {
      state.allAccounts.push(...payload);
    },
    saveAccount(state, payload) {
      state.accounts[payload.id] = payload;
    },
    deleteAccount(state, payload) {
      state.allAccounts = state.allAccounts.filter((item) => item.id !== payload.id);
    },
    setUsersToAnAccount(state, payload) {
      state.accounts[payload.id].users = payload.users;
    },
    resetAccounts(state) {
      state.allAccounts = [];
    },
    updateAccount(state, payload) {
      const foundAccount = state.allAccounts.find((element) => element.id === payload.id);
      const index = state.allAccounts.findIndex((element) => element.id === payload.id);
      Object.keys(payload.body).forEach((key) => {
        foundAccount[key] = payload.body[key];
      });
      state.allAccounts[index] = foundAccount;
      state.accounts[payload.id] = foundAccount;
    },
    updateUserAccount(state, payload) {
      /* eslint-disable max-len */
      const accountIndex = state.allAccounts.findIndex(
        (element) => element.id === payload.accountId
      );
      if (accountIndex !== -1 && state.allAccounts[accountIndex].users) {
        Object.keys(payload.body).forEach((key) => {
          const userToUpdate = state.allAccounts[accountIndex].users.find(
            (user) => user.id === payload.id
          );
          if (userToUpdate) {
            userToUpdate[key] = payload.body[key];
          }
        });
      }
    },
    deleteUserAccount(state, payload) {
      const accountIndex = state.allAccounts.findIndex(
        (element) => element.id === payload.accountId
      );
      state.allAccounts[accountIndex].users = state.allAccounts[accountIndex].users.filter(
        (user) => user.id !== payload.id
      );
    },
    setAccountNameUserView(state, payload) {
      state.accountNameUserView = payload;
    },
    setUserSettings(state, payload) {
      state.userSettings = payload;
    },
  },
  actions: {
    async getAllAccounts({ commit, rootGetters }, payload) {
      try {
        const response = await account.getAllAccounts(payload);
        commit("setAccounts", response);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async getAllAccountToDownloadExcel({ rootGetters }, payload) {
      try {
        const response = await account.getAllAccounts(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async getSearchAccounts({ rootGetters }, payload) {
      try {
        const response = await account.getAllAccounts(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async getAccountById({ commit, rootGetters, getters }, id) {
      try {
        let response = getters.getOneAccount(id);
        if (!response) {
          response = await account.getAccountById(id);
          commit("saveAccount", response);
        }
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async updateAccount({ commit, rootGetters }, body) {
      try {
        const response = await account.updateAccount(body);
        commit("updateAccount", { ...body, id: parseInt(body.id, 10) });
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async getAllUsersByAccountId({ rootGetters }, id) {
      try {
        const response = await account.getAllUsersByAccountId(id);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async createUserByAccountId({ rootGetters }, payload) {
      try {
        const response = await account.createUserByAccountId(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async getAccountSettings({ dispatch, rootGetters }, params) {
      try {
        const response = await account.getAccountSettings();
        await dispatch("setCanDispatchModes", { settings: response, token: params });
        return response;
      } catch (error) {
        return errorMessage(error.response.data.errors[rootGetters.getLanguage]);
      }
    },
    async getAccountModes({ rootGetters }) {
      try {
        const response = await account.getAccountModes();
        return response;
      } catch (error) {
        return errorMessage(error.response.data.errors[rootGetters.getLanguage]);
      }
    },
    setCanDispatchModes(_, params) {
      const canDispatchModes = JSON.parse(params.token.decodedToken.sub).can_dispatch_modes;
      if (canDispatchModes) {
        params.settings.can_dispatch_modes = {};
        params.settings.can_dispatch_modes.ltl = canDispatchModes.can_dispatch_ltl || false;
        params.settings.can_dispatch_modes.ftl = canDispatchModes.can_dispatch_ftl || false;
        params.settings.can_dispatch_modes.expedited = canDispatchModes.can_dispatch_exp || false;
        params.settings.can_dispatch_modes.insurance =
          canDispatchModes.can_dispatch_insurance || false;
        params.settings.can_dispatch_modes.flatbed = canDispatchModes.can_dispatch_flatbed || false;
        params.settings.can_dispatch_modes.reefer = canDispatchModes.can_dispatch_reefer || false;
        params.settings.can_dispatch_modes.drayage = canDispatchModes.can_dispatch_drayage || false;
      }
      localStorage.setItem("user-settings", JSON.stringify(params.settings));
    },
    async updateAccountSettings({ rootGetters }, payload) {
      try {
        const response = await account.updateAccountSettings(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.errors[rootGetters.getLanguage]);
      }
    },
    async updateAccountLogo({ rootGetters }, payload) {
      try {
        const response = await account.updateAccountLogo(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.errors[rootGetters.getLanguage]);
      }
    },
    async updateDocumentsLogo({ rootGetters }, payload) {
      try {
        const response = await account.updateDocumentsLogo(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.errors[rootGetters.getLanguage]);
      }
    },
    async deleteAccount({ rootGetters }, payload) {
      try {
        const response = await account.deleteAccount(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.errors[rootGetters.getLanguage]);
      }
    },
    async updateMagayaMarkups({ rootGetters }, payload) {
      try {
        const response = await account.updateMarkups(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.errors[rootGetters.getLanguage]);
      }
    },
    async getStatement({ rootGetters }, payload) {
      try {
        const response = await account.getStatement(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.errors[rootGetters.getLanguage]);
      }
    },
    async uploadStatement({ rootGetters }, payload) {
      try {
        const response = await account.uploadStatement(payload);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.errors[rootGetters.getLanguage]);
      }
    },
    async getAccountContacts({ rootGetters }, accountId) {
      try {
        const response = await account.getAccountContacts(accountId);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.errors[rootGetters.getLanguage]);
      }
    },
    /* Tracking-Preference */
    async getTrackingPreferences({ rootGetters, commit }) {
      try {
        const response = await account.getTrackingPreferences();
        commit("setTrackingPreferences", response);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.errors[rootGetters.getLanguage]);
      }
    },
    async updateTrackingPreferences({ rootGetters }, payload) {
      try {
        const response = await account.updateTrackingPreferences(payload);
        /* commit("setTrackingPreferences", payload); */
        return response;
      } catch (error) {
        return errorMessage(error.response.data.errors[rootGetters.getLanguage]);
      }
    },
  },
};
