import { errorMessage } from "../../utils/ErrorMessages";
import Repository from "../../services/repositories/RepositoryFactory";

const drayage = Repository.service("drayage");

export default {
  namespaced: true,
  state: {
    chosenLoad: null,
    carriers: null,
    carrierInfo: null,
    ports: null,
    hazmatType: null,
  },
  getters: {
    getChosenLoad: (state) => state.chosenLoad,
    getCarriers: (state) => state.carriers,
    getCarrierInfo: (state) => state.carrierInfo,
    getPortList: (state) => state.ports,
    getHazmatType: (state) => state.hazmatType,
  },
  mutations: {
    setChosenLoad(state, payload) {
      state.chosenLoad = {
        ...payload,
        carrier: 'Drayage Service'
      };
      state.carrierInfo = state.chosenLoad;
    },
    setCarriers(state, carriers) {
      state.carriers = {
        ...carriers,
        carrier: 'Drayage Service'
      };
      state.carrierInfo = state.carriers;
    },
    setPortList(state, portList) {
      state.ports = portList;
    },
    setHazmatType(state, hazmatType) {
      state.hazmatType = hazmatType;
    },
  },
  actions: {
    async getPortsList({ commit, rootGetters }, payload) {
      try {
        const response = await drayage.getListOfPorts(payload, rootGetters.getAbortController);
        commit("setPortList", response.data.data[0]);
        return response;
      } catch (error) {
        return error.response;
      }
    },
    async createLoadDrayage({ commit, rootGetters }, { payload, params }) {
      try {
        const response = await drayage.createLoadDrayage({
          payload,
          params,
        });
        commit('setChosenLoad', response);
        return response;
      } catch (error) {
        if (error.response.status === 400) {
          errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
        }
        if (error.response.status === 404) {
          errorMessage(error.response.data.detail);
        }
        return error.response;
      }
    },
    async updateLoadDrayage({ commit }, payload) {
      try {
        const response = await drayage.updateLoadDrayage(payload);
        commit('setChosenLoad', response);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0]);
      }
    },
    async getPortByLocation({ rootGetters }, locationId) {
      try {
        const response = await drayage.getPortByLocation(locationId);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async getCarriers({ commit }, params) {
      try {
        const response = await drayage.getCarriers(params);
        commit('setCarriers', response);
        return response;
      } catch (error) {
        errorMessage(error.response.data.detail);
        return error.response;
      }
    },
    setHazmatType({ commit }, hazmatType) {
      commit('setHazmatType', hazmatType);
    },
  },
};
