import api from "../clients/axios";

const resource = "drayage";

export default {
  async getListOfPorts(data, abortController) {
    const response = await api.get(`${resource}/ports`, {
      params: { ...data },
      signal: abortController.signal,
    });
    return response;
  },
  async createLoadDrayage({ payload, params }) {
    const response = await api.post(`${resource}/rates`, payload, {
      params: { ...params },
    });
    return response.data;
  },
  async updateLoadDrayage(payload) {
    // Elimina espacios en blanco del load_id
    const loadId = payload.load_id.trim();
    const params = new URLSearchParams({
      terminal: payload.terminal,
      type: payload.type,
      size: payload.size,
      ...((payload.hazmat_type) && { hazmat_type: payload.hazmat_type })
    });
    const response = await api.post(
      `${resource}/requote/${loadId}?${params.toString()}`
    );
    return response.data;
  },
  async getPortByLocation(locationId) {
    const response = await api.get(`${resource}/ports/${locationId}`);
    return response.data;
  },
  async getCarriers(params) {
    const response = await api.get(`${resource}/carrier`, { params });
    return response.data;
  },
};
