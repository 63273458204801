import Vue from "vue";
import Vuex from "vuex";
import SidebarOptions from "../utils/SidebarOptions";
import login from "./modules/login";
import user from "./modules/user";
import account from "./modules/account";
import load from "./modules/load/load";
import standardizers from "./modules/standardizers";
import carriers from "./modules/carriers";
import claims from "./modules/claims";
import role from "./modules/role";
import rolePermissions from "./modules/rolePermissions";
import truckTypes from "./modules/truckTypes";
import pcmiller from "./modules/pcmiller";
import history from "./modules/history";
import share from "./modules/share";
import carriersAlert from "./modules/carriersAlert";
import salesforce from "./modules/salesforce";
import apiKey from "./modules/apiKey";
import quoteFile from "./modules/quoteFile";
import transportSelected from "./modules/transportSelected";
import carousel from "./modules/carousel";
import home from "./modules/home";
import time from "./modules/time";
import magaya from "./modules/magaya";
import color from "./modules/color";
import requestDemo from "./modules/requestDemo";
import cancellation from "./modules/cancellation";
import insurance from "./modules/insurance";
import accountChildren from "./modules/accountChildren";
import accountCommodity from "./modules/accountCommodity";
import statistics from "./modules/statistics";
import paymentHub from "./modules/paymentHub";
import address from "./modules/address";
import drayage from "./modules/drayage";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isOpenSidebar: false,
    cancelRequest: null,
    myLoadsSectionFlag: 0,
    language: "en",
    sidebarOptions: [],
    abortController: new AbortController(),
    isCollapseSidebar: true,
    reRenderSidebarLogo: 0
  },
  getters: {
    getIsOpenSidebar(state) {
      return state.isOpenSidebar;
    },
    getAbortController(state) {
      return state.abortController;
    },
    getCancelRequest(state) {
      return state.cancelRequest;
    },
    getMyLoadsSectionFlag(state) {
      return state.myLoadsSectionFlag;
    },
    getLanguage(state) {
      return state.language;
    },
    getSidebarOptions(state) {
      return state.sidebarOptions;
    },
    getCollapseSidebar(state) {
      return state.isCollapseSidebar;
    },
    getRenderSidebarLogo(state) {
      return state.reRenderSidebarLogo;
    },
  },
  mutations: {
    setAbortController(state) {
      state.abortController = new AbortController();
    },
    triggerAbortController(state) {
      state.abortController.abort();
      state.abortController = new AbortController();
    },
    changeDataSidebar(state, data) {
      state.isOpenSidebar = data;
    },
    setCancelRequest(state, payload) {
      state.cancelRequest = payload;
    },
    setMyLoadsSectionFlag(state) {
      state.myLoadsSectionFlag += 1;
    },
    setLanguage(state, data) {
      state.language = data;
    },
    setSidebarOptions(state, tokenUserInfo) {
      state.sidebarOptions = SidebarOptions.filter((item) => {
        if (item.id === "home" && (tokenUserInfo.is_branded || tokenUserInfo.magaya_guid)) {
          return false;
        }
        if (!item.role.includes("all") && !item.role.includes(tokenUserInfo.role.toLowerCase())) {
          return false;
        }
        if (item.id === "howToVideos" && tokenUserInfo.is_branded) {
          return false;
        }
        return true;
      });
      return state.sidebarOptions;
    },
    setCollapseSidebar(state, data) {
      state.isCollapseSidebar = data;
    },
    setRenderSidebarLogo(state) {
      state.reRenderSidebarLogo += 1;
    },
  },
  modules: {
    login,
    user,
    account,
    load,
    standardizers,
    carriers,
    claims,
    role,
    rolePermissions,
    truckTypes,
    pcmiller,
    history,
    share,
    carriersAlert,
    salesforce,
    apiKey,
    quoteFile,
    transportSelected,
    home,
    carousel,
    time,
    magaya,
    color,
    requestDemo,
    cancellation,
    insurance,
    accountChildren,
    accountCommodity,
    statistics,
    paymentHub,
    address,
    drayage
  },
});
