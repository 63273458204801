import Dexie from "dexie";

let db;

function createDb(payload) {
  db = new Dexie(payload.dbName);
  db.version(2).stores({
    lineItems: "&loadId",
    tenderReferences: "&loadId",
    tenderLineItems: "&loadId",
    customerInformation: "&loadId",
    formData: "&loadId",
    formDataDrayage: "&loadId",
  });
  db.open();
}

function getFromDb(payload) {
  return new Promise((resolve) => {
    resolve(db[payload.collection].get(payload.id));
  });
}

function addToDb(payload) {
  return new Promise((resolve) => {
    resolve(db[payload.collection].add(payload.data));
  });
}

function updateDb(payload) {
  return new Promise((resolve) => {
    resolve(db[payload.collection].put(payload.data));
  });
}

function deleteFromDb(payload) {
  return new Promise((resolve) => {
    resolve(db[payload.collection].delete(payload.id));
  });
}

function deleteIndexedDB(dbName) {
  Dexie.delete(dbName);
}

export default {
  createDb,
  getFromDb,
  addToDb,
  updateDb,
  deleteFromDb,
  deleteIndexedDB,
};
